import {Container, Grid} from "@mui/material";
import styles from "./AuthDefaultLayout.module.css";
import React, {ReactNode} from "react";

type TProps = {
    children: ReactNode;
};

const AuthDefaultLayout: React.FC<TProps> = ({children}) => {
    return (
        <>
            <div>
                <Grid container className={styles.container}>
                    <Grid item xs={12} sm={6} className={styles.primarySide}>
                        <Container>
                            <div className={styles.titleContainer}>
                                <h1 className={`${styles.title} text-default-black`}>whistlebase</h1>
                            </div>
                        </Container>
                    </Grid>
                    <Grid item xs={12} sm={6} className={styles.whiteSide}>
                        <Container maxWidth="xs">{children}</Container>
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

export default AuthDefaultLayout;

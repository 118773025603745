import React, {useEffect, useState} from "react";
import {Box, Button, Card, CardContent, Container, Grid, Typography} from "@mui/material";
import Topbar from "../Topbar/Topbar";
import successImg from "../../assets/success_purchase.svg";
import {useTranslation} from "react-i18next";
import {PaymentApiService} from "../../services/PaymentApiService";
import {Spinner} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {EAuthSessionData} from "../../types/auth";
import Footer from "../../componets/Footer/Footer";
import { useDispatch } from "react-redux";
import { updateHaveSubscription } from "../../redux/userSlice";

const AbbonatoConSuccessoPage = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch(); 
    const paymentApiService = new PaymentApiService();

    useEffect(() => {
        setTimeout(() => {
            paymentApiService.haveSubscription().then((res) => {
                if (!res) {
                    navigateToCheckout();
                } else {
                    dispatch(updateHaveSubscription(true));
                    setLoading(false);
                }
            }).catch(() => {
                navigateToCheckout();
            });
        }, 1000);
    }, []);

    const navigateToHome = () => {
        navigate('/');
    }

    const navigateToCheckout = () => {
        navigate('/completa-profilo/acquista?error=true');
    }

    return (
        <>
            <Topbar/>
            <Container maxWidth="xl">
                <Box marginY={10}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Card variant="outlined">
                                <CardContent>
                                    <Box mb={3}>
                                        <Typography variant="h4" component="h1" align="center">
                                            {t('COMPLETE_PROFILE.SUCCESS.TITLE')}
                                        </Typography>
                                    </Box>
                                    <Typography variant="body1" component="h2" align="center">
                                        {t('COMPLETE_PROFILE.SUCCESS.SUBTITLE')}
                                    </Typography>
                                    <Box display="flex" justifyContent="center" marginY={4}>
                                        <img src={successImg} alt="success purchase" width={300}/>
                                    </Box>
                                    <Box display="flex" justifyContent="center" marginY={4}>
                                        {!loading &&
                                            <Button variant="contained" color="secondary" sx={{minWidth: 300}}
                                                    disableElevation size="large" onClick={navigateToHome}>
                                                <Box paddingY="5px">
                                                    {t('COMPLETE_PROFILE.SUCCESS.BUTTON')}
                                                </Box>
                                            </Button>}
                                        {loading &&
                                            <Button variant="contained" color="secondary" sx={{minWidth: 300}}
                                                    disableElevation size="large">
                                                <Box paddingY="5px">
                                                    <Spinner animation="border"/>
                                                </Box>
                                            </Button>
                                        }
                                    </Box>

                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
            <Footer/>
        </>
    )
}


export default AbbonatoConSuccessoPage;

import {ApiService} from "./ApiService";
import {IBillingInfo, ICheckoutB, IPaymentTransaction, IPlan, IValidSubscription} from "../types/payment";
import {PAYMENT_URL} from "../api/api";
import {IAuthSignInResponse} from "../types/auth";
import {IApiResponse} from "../types/commons";

export class PaymentApiService extends ApiService {

    constructor() {
        super(PAYMENT_URL);
    }

    public billingInfo(): Promise<IBillingInfo> {
        return this.get<IBillingInfo>("/customer/me");
    }

    public haveBillingInfo(): Promise<boolean> {
        return new Promise<boolean>(async (resolve) => {
            try {
                await this.billingInfo();
                return resolve(true);
            } catch (error) {
                return resolve(false);
            }
        });
    }

    public saveBillingInfo(billingInfo: IBillingInfo, haveBilling?: boolean): Promise<IBillingInfo> {
        return new Promise<IBillingInfo>(async (resolve, reject) => {
            try {
                const result = haveBilling ? await this._updateBillingInfo(billingInfo) : await this._createBillingInfo(billingInfo);
                return resolve(result);
            } catch (error) {
                return reject(error);
            }
        });
    }

    public getPlans(): Promise<IPlan[]> {
        return this.listSearch<IPlan>("/plan");
    }

    public haveSubscription(): Promise<boolean> {
        return new Promise<boolean>(async (resolve) => {
            try {
                const response = await this.get<IValidSubscription>('/subscription/customer/valid');
                return resolve(response.valid);
            } catch (error) {
                return resolve(false);
            }
        });
    }

    public startCheckout(plan: IPlan): Promise<string> {
        return this.post<ICheckoutB, string>("/customer/createCheckout", {productId: plan.stripe_id_plan});
    }

    public paidTransactions(): Promise<IPaymentTransaction[]> {
        return new Promise<IPaymentTransaction[]>(async (resolve, reject) => {
            try {
                const result = await this.listSearch<IPaymentTransaction>("transaction", {state: "paid"});
                return resolve(result.map((transaction) => {
                    transaction.updatedAt = new Intl.DateTimeFormat('en-US').format(new Date(transaction.updatedAt ?? ''));
                    return transaction;
                }));
            } catch (error) {
                return reject(error);
            }
        });
    }

    public billingPortalLink(): Promise<string> {
        return this.get<string>("/customer/billing/portal");
    }

    public currentPlan(): Promise<IPlan> {
        return this.get<IPlan>("/customer/my-plan");
    }

    private async _updateBillingInfo(billingInfo: IBillingInfo): Promise<IBillingInfo> {
        return this.patch<IBillingInfo, IBillingInfo>("/customer/me", billingInfo);
    }

    private _createBillingInfo(billingInfo: IBillingInfo): Promise<IBillingInfo> {
        return new Promise<IBillingInfo>(async (resolve, reject) => {
            try {
                const result = await this.post<IBillingInfo, IBillingInfo>("/customer/me", billingInfo);
                return resolve(result);
            } catch (error) {
                return reject(error);
            }
        });
    }
}

import { ApiService } from "./ApiService";
import { AUTH_URL } from "../api/api";
import {
	EAuthRole,
	EAuthSessionData, IAuthForgotPasswordPayload, IAuthGroup, IAuthRefreshToken,
	IAuthResetPasswordResponse,
	IAuthSignIn,
	IAuthSignInResponse,
	IResetPasswordPayload,
	IValidateTenant
} from "../types/auth";
import { PaymentApiService } from "./PaymentApiService";
import { RegistryApiService } from "./RegistryApiService";
import { jwtDecode } from "jwt-decode";
import { IResendEmail } from "../type";

export class AuthApiService extends ApiService {
	private _paymentApiService: PaymentApiService;
	private _registryApiService: RegistryApiService;

	constructor() {
		super(AUTH_URL);
		this._paymentApiService = new PaymentApiService();
		this._registryApiService = new RegistryApiService();
	}

	signIn(body: IAuthSignIn): Promise<any> {
		return new Promise<any>(async (resolve, reject) => {
			try {
				const response = await this.post<IAuthSignIn, IAuthSignInResponse>("/auth/login", body);
				sessionStorage.setItem(EAuthSessionData.ACCESS_TOKEN, response.aToken);
				const { role } = jwtDecode<any>(response.aToken);
				
				let haveSubscription = false;
				let isManual = false;
				if (role === EAuthRole.SUPER_ADMIN || role === EAuthRole.PLATFORM_ADMIN) {
					haveSubscription = true;
					isManual = true;
				} else {
					haveSubscription = await this._paymentApiService.haveSubscription();
					isManual = await this._registryApiService.isManual();
				}
				
				return resolve({ res: response, role: role, isManual: isManual, haveSubscription: haveSubscription, username: body.email });
			} catch (e) {
				return reject(e);
			}
		});
	}

	resetPassword(pwd: string, token: string): Promise<IAuthResetPasswordResponse> {
		return new Promise<IAuthResetPasswordResponse>(async (resolve, reject) => {
			try {
				const payload: IResetPasswordPayload = { pwd };
				const response = await this.post<IResetPasswordPayload, IAuthResetPasswordResponse>("/auth/recoveryPassword?token=" + token, payload);
				return resolve(response);
			} catch (e) {
				return reject(e);
			}
		});
	}

	resendEmail(body: IResendEmail): Promise<any> {
		return this.post("/email/resend", body)
	}

	newPassword(pwd: string, token: string) {
		const payload: IResetPasswordPayload = { pwd };
		return this.post<IResetPasswordPayload, IAuthResetPasswordResponse>("/auth/newPassword?token=" + token, payload);
	}

	changePassword(pwd: string) {
		const payload: IResetPasswordPayload = { pwd };
		return this.post<IResetPasswordPayload, IAuthResetPasswordResponse>("/auth/changePassword", payload);
	}

	validateTenant(body: IValidateTenant) {
		return this.post<IValidateTenant, IAuthGroup>("/group/public/validTenant", body);
	}

	forgotPassword(body: IAuthForgotPasswordPayload) {
		return this.post<IAuthForgotPasswordPayload, any>("/auth/forgotPassword", body);
	}

	logout(body: IAuthRefreshToken) {
		return this.post("/auth/logout", body);
	}

	refreshToken(): Promise<any> {
		return new Promise<any>(async (resolve, reject) => {
			try {
				const response = await this.post<IAuthRefreshToken, IAuthSignInResponse>("/auth/refresh", { refreshToken: "" });
				sessionStorage.setItem(EAuthSessionData.ACCESS_TOKEN, response.aToken);
				const { role } = jwtDecode<any>(response.aToken);
				
				let haveSubscription = false;
				let isManual = false;
				if (response.role === EAuthRole.SUPER_ADMIN || response.role === EAuthRole.PLATFORM_ADMIN) {
					haveSubscription = true;
					isManual = true;
				} else {
					haveSubscription = await this._paymentApiService.haveSubscription();
					isManual = await this._registryApiService.isManual();
				}
				
				return resolve({ res: response, role: role, isManual: isManual, haveSubscription: haveSubscription, username: response.username });
			} catch (e) {
				return reject(e);
			}
		});
	}
}

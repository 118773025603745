export const BE_ADDRESS = process.env.REACT_APP_API_URL;

export const PROTOCOL = process.env.REACT_APP_API_PROTOCOL + "://";
export const AUTH_URL = PROTOCOL + BE_ADDRESS + "/authentication"; // :3003

//PREFIX
export const WHISTLEBLOWER_PREFIX = process.env.WB_PREFIX;
export const REGISTER_PREFIX = process.env.REG_PREFIX;
export const WORKFLOW_PREFIX = process.env.WF_PREFIX;

//WHITLEBLOWER
export const WHISTLEBLOWER_URL = PROTOCOL + BE_ADDRESS + "/wb"; // :3005
export const REGISTER_URL = PROTOCOL + BE_ADDRESS + "/reg"; // :3001
export const WORKFLOW_URL = PROTOCOL + BE_ADDRESS + "/wflow"; // :3006
export const PAYMENT_URL = PROTOCOL + BE_ADDRESS + "/payment";
export const MENU_URL = PROTOCOL + BE_ADDRESS + "/menu";

export const REPORT_TYPE_URL = WHISTLEBLOWER_URL + "/report-type/list/search";
export const SUBJECT_INVOLVED_TYPE_URL = WHISTLEBLOWER_URL + "/subject-involved-type/list/search";
export const CONTACT_TYPE_URL = REGISTER_URL + "/contact-type/list/search";
export const REGISTRY_TYPE_URL = REGISTER_URL + "/registry-type/list/search/";
export const ADDRESS_TYPE_URL = REGISTER_URL + "/registry-address-type/list/search/";
export const REGISTRY_DETAIL = REGISTER_URL + "/registry/detail/";
export const REGISTRY_INFO = REGISTER_URL + "/registry/info/me";

export const POST_REPORT_FULL = WHISTLEBLOWER_URL + "/report/full";
export const UPLOAD_URL = WHISTLEBLOWER_URL + "/attachment/upload/";
export const POST_REPORT_BY_KEYCODE = WHISTLEBLOWER_URL + "/report/search/keyCode";

export const API_POST_VALIDATE_TENANT = AUTH_URL + "/group/public/validTenant";

//get tabella Segnalatori
export const API_GET_TAB = WHISTLEBLOWER_URL + "/report/list/search";

export const API_GET_INFO_REPORT_ADMIN = WHISTLEBLOWER_URL + "/report";
export const API_GET_INFO_REPORT_USER = WHISTLEBLOWER_URL + "/report/search/keyCode";
export const API_GET_TAB_REPORT_ADMIN = WHISTLEBLOWER_URL + "/report/list/count";

export const API_GET_DOWNLOAD = WHISTLEBLOWER_URL + "/attachment/download";

export const API_POST_COMMENT = WHISTLEBLOWER_URL + "/report-comment";

export const API_POST_COMMENT_PUBLIC = WHISTLEBLOWER_URL + "/report-comment/create-public";

export const API_POST_CHANGE_STATE = WHISTLEBLOWER_URL + "/report/changeState";

export const API_GET_TRASITION_STATE = WORKFLOW_URL + "/transition-role/list/search";

//LOGIN
export const API_LOGIN = AUTH_URL + "/auth/login";

//AZIENDA GET
export const API_GET_REGISTRY_LIST_SEARCH = REGISTER_URL + "/registry/list/search";
export const API_GET_REGISTRY_LIST_COUNT = REGISTER_URL + "/registry/list/count";

//AZIENDA / UTENTI POST
export const API_POST_ADD_AZIENDA = REGISTER_URL + "/registry/full";
export const API_POST_ADD_UTENTE_AZIENDA = REGISTER_URL + "/registry/full";

//LOGO AZIENDA
export const API_GET_LOGO_AZIENDA = REGISTER_URL + "/registry/search/imageByTenant";

export const API_AUTH_FORGOT_PASSWORD = AUTH_URL + "/auth/forgotPassword";
export const API_AUTH_RECOVERY_PASSWORD = AUTH_URL + "/auth/recoveryPassword";
export const API_AUTH_CHANGE_PASSWORD = AUTH_URL + "/auth/changePassword";
export const API_AUTH_NEW_PASSWORD = AUTH_URL + "/auth/newPassword";

export const API_LINK_LIST_TENANT = MENU_URL + "/link/list/link-from-tenant";

export const API_GET_TRANSLATION_REPORT_TYPE = WHISTLEBLOWER_URL + "/translations/list/search";
export const API_GET_TRANSLATION_STATUS_TYPE = WORKFLOW_URL + "/translations/list/search";

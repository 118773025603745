import i18n from "../i18n";

const fullLanguageCode = i18n.language;
const languageCode = fullLanguageCode.split("-")[0].toLowerCase();
// const languageCode = "";

export const WHISTLEBASE_WEBSITE = "https://whistlebase.com/";

const urlConfig: any = {
	it: {
		COOKIE_POLICY: WHISTLEBASE_WEBSITE + languageCode + "/cookie-policy",
		PRIVACY_POLICY: WHISTLEBASE_WEBSITE + languageCode + "/informativa-sulla-privacy/",
		ORGANIZZATIONAL_AND_TECNICAL_MEASURES: WHISTLEBASE_WEBSITE + languageCode + "/misure-organizzative-e-tecniche-per-la-protezione-dei-dati/",
		TERMS_AND_CONDITIONS: WHISTLEBASE_WEBSITE + languageCode + "/termini-e-condizioni/",
		PROTECTION_OF_PERSONAL_DATA: WHISTLEBASE_WEBSITE + languageCode + "/accordo-di-trattamento-dei-dati/",
		USER_MANUAL: WHISTLEBASE_WEBSITE + languageCode + "/wp-content/uploads/2023/12/Whistlebase-Manuale-utente.pdf",
	},
	cs: {
		COOKIE_POLICY: WHISTLEBASE_WEBSITE + languageCode + "/cookie-policy",
		PRIVACY_POLICY: WHISTLEBASE_WEBSITE + languageCode + "/zasady-ochrany-osobnich-udaju/",
		ORGANIZZATIONAL_AND_TECNICAL_MEASURES: WHISTLEBASE_WEBSITE + languageCode + "/organizacni-a-technicka-opatreni-pro-ochranu-dat/",
		TERMS_AND_CONDITIONS: WHISTLEBASE_WEBSITE + languageCode + "/podminky-pouziti/",
		PROTECTION_OF_PERSONAL_DATA: WHISTLEBASE_WEBSITE + languageCode + "/mlouva-o-zpracovani-udaju/",
		USER_MANUAL: WHISTLEBASE_WEBSITE + languageCode + "/wp-content/uploads/2023/12/Whistlebase-Manuale-utente.pdf",
	},
};

const defaultLanguage = "it";

const currentLanguageUrls = urlConfig[languageCode] || urlConfig[defaultLanguage];

export const COOKIE_POLICY = currentLanguageUrls.COOKIE_POLICY;
export const PRIVACY_POLICY = currentLanguageUrls.PRIVACY_POLICY;
export const ORGANIZZATIONAL_AND_TECNICAL_MEASURES = currentLanguageUrls.ORGANIZZATIONAL_AND_TECNICAL_MEASURES;
export const TERMS_AND_CONDITIONS = currentLanguageUrls.TERMS_AND_CONDITIONS;
export const PROTECTION_OF_PERSONAL_DATA = currentLanguageUrls.PROTECTION_OF_PERSONAL_DATA;
export const USER_MANUAL = currentLanguageUrls.USER_MANUAL;

import {useState} from "react";
import BasicButton from "../../../compontensNuovi/buttons/BasicButton";
import sharedStyles from "../components/AuthDefaultLayout/Shared.module.css";
import {useTranslation} from "react-i18next";
import {Button, Form, InputGroup, Spinner} from "react-bootstrap";
import AuthDefaultLayout from "../components/AuthDefaultLayout/AuthDefaultLayout";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {Formik, FormikTouched} from "formik";
import {IAuthSignUp} from "../../../types/auth";
import {RegistryApiService} from "../../../services/RegistryApiService";
import {IAlert} from "../../../types/commons";
import {Alert, Box, Divider, Grid} from "@mui/material";
import {Link, useNavigate} from "react-router-dom";
import signUpSchema from "../formModels/signUpSchema";
import signUpInitialValues from "../formModels/signUpInitialValues";
import {PRIVACY_POLICY, TERMS_AND_CONDITIONS} from "../../../utils/links";

const SignUpPage = () => {
	const {t} = useTranslation();
	const [mostraPwd, setMostraPwd] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		severity: "error",
		message: "SIGN_UP.SUBMIT_ERROR",
		dismissible: true,
		onClose: () => {
			setAlert({...alert, show: false});
		},
	});
	const registryApiService = new RegistryApiService();
	const navigate = useNavigate();

	const schema = signUpSchema;

	const initialValues = signUpInitialValues;

	const onSubmit = (values: IAuthSignUp) => {
		setIsLoading(true);
		registryApiService
			.signUp(values)
			.then(() => {
				setIsLoading(false);
				navigate("/auth/confirm-registration");
			})
			.catch((error: any) => {
				console.log("error sign", error);
				setIsLoading(false);
				
				setAlert({...alert, show: true, message: error});
			});
	};

	const acceptComponent = () => {
		return (
			<Box marginTop="-20px" color="black">
				{t("SIGN_UP.ACCEPT_1")}
				<a style={{textDecoration: "underline"}} target="_blank" href={PRIVACY_POLICY} rel="noreferrer">
					{t("SIGN_UP.ACCEPT_2")}
				</a>
				{t("SIGN_UP.ACCEPT_3")}
				<a style={{textDecoration: "underline"}} href={TERMS_AND_CONDITIONS} target="_blank" rel="noreferrer">
					{t("SIGN_UP.ACCEPT_4")}
				</a>
			</Box>
		);
	};

	return (
		<AuthDefaultLayout>
			<div className={sharedStyles.labels}>{t("SIGN_UP.TITLE")}</div>
			<div className={sharedStyles.container}>
				{alert.show && (
					<Alert className="mb-3" severity={alert.severity} onClose={alert.onClose}>
						{t(alert.message)}
					</Alert>
				)}
				<Formik<IAuthSignUp> initialValues={initialValues} validationSchema={schema} onSubmit={onSubmit}>
					{({handleSubmit, handleChange, values, handleBlur, touched, errors, isValid}) => {
						const formIsTouched = Object.keys(touched).some((field) => touched[field as keyof FormikTouched<IAuthSignUp>]);
						const isSubmitDisabled = !formIsTouched || !isValid;
						return (
							<Form noValidate onSubmit={handleSubmit}>
								<Form.Group>
									<InputGroup className={sharedStyles.inputGroup} hasValidation>
										<Form.Control
											name="firstName"
											placeholder={t("SIGN_UP.FIRST_NAME") + " *"}
											value={values.firstName}
											onChange={handleChange}
											maxLength={16}
											isInvalid={touched.firstName && !!errors.firstName}
											onBlur={handleBlur}
											readOnly={isLoading}
										/>
										<Form.Control.Feedback type="invalid">{errors.firstName ? t(errors.firstName) : ""}</Form.Control.Feedback>
									</InputGroup>
								</Form.Group>
								<Form.Group>
									<InputGroup className={sharedStyles.inputGroup} hasValidation>
										<Form.Control
											name="lastName"
											placeholder={t("SIGN_UP.LAST_NAME") + " *"}
											value={values.lastName}
											onChange={handleChange}
											maxLength={16}
											isInvalid={touched.lastName && !!errors.lastName}
											onBlur={handleBlur}
											readOnly={isLoading}
										/>
										<Form.Control.Feedback type="invalid">{errors.lastName ? t(errors.lastName) : ""}</Form.Control.Feedback>
									</InputGroup>
								</Form.Group>
								<Form.Group>
									<InputGroup className={sharedStyles.inputGroup} hasValidation>
										<Form.Control
											name="businessName"
											placeholder={t("SIGN_UP.BUSINESS_NAME") + " *"}
											value={values.businessName}
											onChange={handleChange}
											maxLength={40}
											isInvalid={touched.businessName && !!errors.businessName}
											onBlur={handleBlur}
											readOnly={isLoading}
										/>
										<Form.Control.Feedback type="invalid">{errors.businessName ? t(errors.businessName) : ""}</Form.Control.Feedback>
									</InputGroup>
								</Form.Group>
								<Form.Group>
									<InputGroup className={sharedStyles.inputGroup} hasValidation>
										<Form.Control
											name="phone"
											placeholder={t("SIGN_UP.PHONE") + " *"}
											maxLength={13}
											value={values.phone}
											onChange={handleChange}
											isInvalid={touched.phone && !!errors.phone}
											onBlur={handleBlur}
											readOnly={isLoading}
										/>
										<Form.Control.Feedback type="invalid">{errors.phone ? t(errors.phone) : ""}</Form.Control.Feedback>
									</InputGroup>
								</Form.Group>
								<Form.Group>
									<InputGroup className={sharedStyles.inputGroup} hasValidation>
										<Form.Control
											name="email"
											placeholder={t("SIGN_UP.WORKING_EMAIL") + " *"}
											value={values.email}
											onChange={handleChange}
											isInvalid={touched.email && !!errors.email}
											onBlur={handleBlur}
											readOnly={isLoading}
										/>
										<Form.Control.Feedback type="invalid">{errors.email ? t(errors.email) : ""}</Form.Control.Feedback>
									</InputGroup>
								</Form.Group>
								<Form.Group>
									<InputGroup className={sharedStyles.inputGroupWithButton}>
										<Form.Control
											name="password"
											type={mostraPwd ? "text" : "password"}
											required
											maxLength={16}
											placeholder="Password *"
											value={values.password}
											onChange={handleChange}
											onBlur={handleBlur}
											readOnly={isLoading}
											isInvalid={touched.password && !!errors.password}
										/>
										<Button onClick={() => setMostraPwd(!mostraPwd)} variant={"outline-light"}>
											{mostraPwd ? <VisibilityOff></VisibilityOff> : <Visibility></Visibility>}
										</Button>
										<Form.Control.Feedback type="invalid">{errors.password ? t(errors.password) : ""}</Form.Control.Feedback>
									</InputGroup>
								</Form.Group>
								<Form.Group>
									<Form.Check name="terms" className={"mb-3"} type="checkbox" onChange={handleChange} readOnly={isLoading} label={acceptComponent()} />
								</Form.Group>
								<BasicButton text={!isLoading ? t("SIGN_UP.SIGN_UP") : ""} maxWidth={"100%"} disabled={isSubmitDisabled || isLoading}>
									{isLoading ? <Spinner animation="border" /> : null}
								</BasicButton>
								<Grid container className={"mt-5 text-center"}>
									<Grid item xs={12} className={"mb-3"}>
										<Divider className={sharedStyles.divider}></Divider>
									</Grid>
									<Grid item xs={12} sm={6}>
										<Link to={"../login"}>{t("I_ALREADY_HAVE_AN_ACCOUNT")}</Link>
									</Grid>
								</Grid>
							</Form>
						);
					}}
				</Formik>
			</div>
		</AuthDefaultLayout>
	);
};

export default SignUpPage;

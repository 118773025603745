import React, {ChangeEvent, useState} from "react";
import {Button, Grid, TextField} from "@mui/material";
import {emailRegex, intlPhoneRegex, phoneRegex} from "../../utils/regex";
import {SedeNew} from "../../type";
import {toast_error, toast_success} from "../../utils/custom-toast";
import {ToastContainer} from "react-toastify";
import styles from "./AddAziendaNuova.module.css";
import {Container, Spinner} from "react-bootstrap";
import Topbar from "../Topbar/Topbar";
import BackButton from "../../componets/Buttons/BackButton";
import {useNavigate, useParams} from "react-router-dom";
import {API_POST_ADD_AZIENDA} from "../../api/api";
import {EContactType, ERegistryAddressType, ERegistryType} from "../../types/registry";
import {EAuthRole, EAuthSessionData} from "../../types/auth";
import Footer from "../../componets/Footer/Footer";
import {t} from "i18next";
import AddAziendaValidationSchema from "./AddAziendaValidationSchema";
import {RegistryApiService} from "../../services/RegistryApiService";
import {useTranslation} from "react-i18next";

const AddAziendaNuova: React.FC = () => {
	const navigate = useNavigate();
	const initialAziendaState: SedeNew = {
		_id: "",
		image: "",
		business_name: "",
		vat_number: "",
		website: "",
		email: "",
		phoneNumber: "",
		topon: "",
		street: "",
		number: "",
		postal_code: "",
		city: "",
		province: "",
		state: "",
		address: "",
	};
	const [azienda, setAzienda] = useState<SedeNew>(initialAziendaState);
	const [isLoading, setIsLoading] = useState(false);
	const [imageBase64, setImageBase64] = useState<string>("");
	const aToken = sessionStorage.getItem(EAuthSessionData.ACCESS_TOKEN);
	const {idOwner} = useParams<{idOwner: string}>();

	const validationSchema = AddAziendaValidationSchema;
	const {t} = useTranslation();
	const registryApiService = new RegistryApiService();

	const emailValidation = emailRegex.test(azienda.email);
	const isPhoneValid = intlPhoneRegex.test(azienda.phoneNumber);

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		const {name, value} = event.target;
		setAzienda((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const extractToponAndAddress = (addressString: string) => {
		const addressArray = addressString.trim().split(" ");
		const topon = addressArray[0];
		const address = addressArray.slice(1).join(" ");
		return {topon, address};
	};

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		setIsLoading(true);
		event.preventDefault();

		const form = event.target as HTMLFormElement;
		const addressInput = form.elements.namedItem("address") as HTMLInputElement;
		const addressValue = addressInput.value;

		const {topon, address} = extractToponAndAddress(addressValue);

		const requestBody = {
			id_registry_owner: idOwner,
			registry_type: ERegistryType.AZIENDA,
			business_name: azienda.business_name,
			vat_number: azienda.vat_number,
			image: imageBase64,
			user: {
				email: azienda.email,
				applicationsEnabled: ["wb"],
				role: EAuthRole.ADMIN,
			},
			isManual: true,
			contact: [
				{
					contact_type: EContactType.SITO_WEB,
					contact: azienda.website,
				},
				{
					contact_type: EContactType.EMAIL,
					contact: azienda.email,
				},
				{
					contact_type: EContactType.PHONE,
					contact: azienda.phoneNumber,
				},
			].filter(Boolean),
			registry_address: [
				{
					registry_address_type: ERegistryAddressType.LEGALE,
					latitude: null,
					longitude: null,
					main: true,
					topon: topon,
					street: address,
					number: parseInt(azienda.number),
					postal_code: azienda.postal_code,
					city: azienda.city,
					province: azienda.province,
					state: azienda.state,
				},
			],
		};

		// Validation
		let hasError = false;

		if (!azienda.business_name) {
			toast_error(t("ERRORS.NEW_COMPANY.REQUIRED_NAME"));
			hasError = true;
		}

		if (!azienda.vat_number) {
			toast_error(t("ERRORS.NEW_COMPANY.REQUIRED_VAT_NUMBER"));
			hasError = true;
		}

		if (!azienda.number || !azienda.address || !azienda.postal_code || !azienda.city || !azienda.province || !azienda.state) {
			toast_error(t("ERRORS.NEW_COMPANY.REQUIRED_ADDRESS"));
			hasError = true;
		}

		if (!azienda.website) {
			toast_error(t("ERRORS.NEW_COMPANY.REQUIRED_WEB_SITE"));
			hasError = true;
		}

		if (!azienda.email) {
			toast_error(t("ERRORS.NEW_COMPANY.REQUIRED_EMAIL"));
			hasError = true;
		} else if (!emailRegex.test(azienda.email)) {
			toast_error(t("ERRORS.NEW_COMPANY.INVALID_EMAIL"));
			hasError = true;
		}

		if (!azienda.phoneNumber) {
			toast_error(t("ERRORS.NEW_COMPANY.REQUIRED_PHONE"));
			hasError = true;
		} else if (!phoneRegex.test(azienda.phoneNumber)) {
			toast_error(t("ERRORS.NEW_COMPANY.INVALID_PHONE"));
			hasError = true;
		}

		if (hasError) {
			return;
		}

		registryApiService.createCompany(requestBody)
			.then((response) => {

				if (response) {
					setIsLoading(false);
					setAzienda(initialAziendaState);
					toast_success(t("OPERATION_SUCCESS"));
                    navigate(`/Homepage`);
				}
			})
			.catch((error) => {
				setIsLoading(false);
				toast_error(error)
			});
	};

	const handleBackClick = () => {
		navigate(`/Homepage`);
	};

	const [uploadedFileName, setUploadedFileName] = useState<string>(t("NEW_COMPANY.NO_LOGO"));
	const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
		const files = event.target.files;
		if (files && files.length > 0) {
			const file = files[0];
			if (file && file.type === "image/png") {
				const reader = new FileReader();
				reader.onload = () => {
					if (reader.result) {
						setImageBase64(reader.result.toString());
						setUploadedFileName(file.name);
					}
				};
				reader.readAsDataURL(file);
			} else {
				console.error("File non valido. Seleziona un file PNG.");
			}
		}
	};

	return (
		<>
			<Grid sx={{marginTop: 1}}>
				<Topbar />
			</Grid>
			<Container>
				<div className={styles.container}>
					<ToastContainer position="top-right" autoClose={3000} />
					<form onSubmit={handleSubmit}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Grid container spacing={2} sx={{marginTop: 8}}>
									{/* CREA NUOVA AZIENDA */}
									<Grid item xs={12} sm={12}>
										<h1>{t("NEW_COMPANY.TITLE")}</h1>
									</Grid>
									<Grid item xs={12} sm={6}>
										<TextField
											label={t("NEW_COMPANY.NAME_COMPANY")}
											type="text"
											name="business_name"
											value={azienda.business_name}
											onChange={handleChange}
											error={!azienda.business_name}
											fullWidth
											required
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<TextField
											label={t("NEW_COMPANY.VAT_NUMBER")}
											type="text"
											name="vat_number"
											value={azienda.vat_number}
											onChange={handleChange}
											error={!azienda.vat_number}
											fullWidth
											required
										/>
									</Grid>
									<Grid item xs={12} sm={12}>
										<div> {t("NEW_COMPANY.ADDRESS.TITLE")}</div>
									</Grid>
									<Grid item xs={12} sm={7}>
										<TextField
											label={t("NEW_COMPANY.ADDRESS.STREET")}
											type="text"
											name="address"
											value={azienda.address}
											onChange={handleChange}
											error={!azienda.address}
											fullWidth
											required
										/>
									</Grid>
									<Grid item xs={12} sm={2}>
										<TextField
											label={t("NEW_COMPANY.ADDRESS.NUMBER")}
											type="text"
											name="number"
											value={azienda.number}
											onChange={handleChange}
											error={!azienda.number}
											fullWidth
											required
										/>
									</Grid>
									<Grid item xs={12} sm={3}>
										<TextField
											label={t("NEW_COMPANY.ADDRESS.POSTAL_CODE")}
											type="text"
											name="postal_code"
											value={azienda.postal_code}
											onChange={handleChange}
											error={!azienda.postal_code}
											fullWidth
											required
										/>
									</Grid>
									<Grid item xs={12} sm={4}>
										<TextField
											label={t("NEW_COMPANY.ADDRESS.CITY")}
											type="text"
											name="city"
											value={azienda.city}
											onChange={handleChange}
											fullWidth
											error={!azienda.city}
										/>
									</Grid>
									<Grid item xs={12} sm={4}>
										<TextField
											label={t("NEW_COMPANY.ADDRESS.PROVINCE")}
											type="text"
											name="province"
											value={azienda.province}
											onChange={handleChange}
											fullWidth
											error={!azienda.province}
											required
										/>
									</Grid>
									<Grid item xs={12} sm={4}>
										<TextField
											label={t("NEW_COMPANY.ADDRESS.COUNTRY")}
											type="text"
											name="state"
											value={azienda.state}
											onChange={handleChange}
											fullWidth
											error={!azienda.state}
											required
										/>
									</Grid>
								</Grid>
								{/* CONTATTI */}
								<Grid container spacing={2} sx={{marginTop: 1}}>
									<Grid item xs={12} sm={12}>
										<div>{t("NEW_COMPANY.CONTACTS.TITLE")}</div>
									</Grid>
									<Grid item xs={12} sm={6}>
										<TextField
											label={
												!azienda.phoneNumber
													? t("NEW_COMPANY.CONTACTS.PHONE.PHONE")
													: !isPhoneValid
													? t("NEW_COMPANY.CONTACTS.PHONE.INVALID_PHONE")
													: t("NEW_COMPANY.CONTACTS.PHONE.PHONE")
											}
											error={!azienda.phoneNumber || !isPhoneValid}
											type="text"
											name="phoneNumber"
											value={azienda.phoneNumber}
											onChange={handleChange}
											fullWidth
											required
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<TextField
											label={
												!azienda.email
													? t("NEW_COMPANY.CONTACTS.EMAIL.EMAIL")
													: !emailValidation
													? t("NEW_COMPANY.CONTACTS.EMAIL.INVALID_EMAIL")
													: t("NEW_COMPANY.CONTACTS.EMAIL.EMAIL")
											}
											error={!emailValidation}
											type="email"
											name="email"
											value={azienda.email}
											onChange={handleChange}
											fullWidth
											required
										/>
									</Grid>
									<Grid item xs={12} sm={12}>
										<TextField
											label={t("NEW_COMPANY.CONTACTS.WEBSITE")}
											type="text"
											name="website"
											value={azienda.website}
											onChange={handleChange}
											error={!azienda.website}
											fullWidth
											required
										/>
									</Grid>
								</Grid>
							</Grid>
							{/* UPLOAD FILE PNG */}
							<Grid item xs={1} sm={12} sx={{display: "flex"}}>
								<Button component="label" sx={{backgroundColor: "var(--primary-500)", color: "black", borderRadius: "5px"}}>
									{t("NEW_COMPANY.LOGO_UPLOAD")}
									<input type="file" accept=".png" style={{display: "none"}} onChange={handleFileUpload} />
								</Button>
								<div style={{marginLeft: "10px", marginTop: "5px"}}>{uploadedFileName}</div>
							</Grid>
							<Grid item xs={1} sm={12} sx={{display: "flex", justifyContent: "flex-end"}}>
								<Grid item xs={1} sm={12} sx={{display: "flex", justifyContent: "flex-end"}}>
									{/* BUTTON BACK TO HomeListaAziende */}
									<BackButton onClick={handleBackClick} />
									<Grid item xs={1} sm={12} sx={{display: "flex", justifyContent: "flex-end"}}>
										{/* BUTTON SAVE FORM */}
										<Button
											disabled={isLoading}
											style={{backgroundColor: isLoading ? "var(--grey-200)" : "var(--primary-500)", color: "black"}}
											variant="contained"
											type="submit"
										>
											{!isLoading ? t("CONFIRM") : <Spinner animation="border" />}
										</Button>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</form>
				</div>
			</Container>
			<Footer />
		</>
	);
};

export default AddAziendaNuova;

import {Button} from "@mui/material";
import React, {CSSProperties} from "react";

interface Props {
    onClick?: any;
    text: string;
    backgroundColor?: string;
    backgroundColorHover?: string;
    maxWidth?: string;
    borderColor?: string;
    borderColorHover?: string;
    color?: string;
    disabled?: boolean;
    type?: "button" | "submit" | "reset";
    children?: React.ReactNode;
    style?: CSSProperties | undefined;
}

const BasicButton: React.FC<Props> = ({
                                          onClick,
                                          text,
                                          backgroundColor,
                                          maxWidth,
                                          borderColor,
                                          color,
                                          disabled,
                                          type = "submit",
                                          backgroundColorHover,
                                          borderColorHover,
                                          children,
                                          style,
                                      }) => {
    return (
        <>
            <div style={{maxWidth: maxWidth ?? "20vw", display: "flex"}}>
                <Button
                    variant="outlined"
                    style={style}
                    sx={{
                        flex: 1,
                        maxWidth: maxWidth ?? "100%",
                        backgroundColor: backgroundColor ?? "var(--primary-500)",
                        borderColor: borderColor ?? "var(--primary-500)",
                        color: color ?? "var(--black)",
                        fontSize: "0.8rem",
                        fontWeight: "bold",
                        textTransform: "none",
                        borderRadius: 30,
                        boxShadow: "0px 15px 10px -7px rgba(0,0,0,0.3)",
                        "&:active": {
                            borderColor: "black",
                        },
                        "&:hover": {
                            borderColor: borderColorHover ?? "var(  --primary-800)",
                            color: "var(--black)",
                            backgroundColor: backgroundColorHover ?? "var(  --primary-800)",
                        },
                    }}
                    type={type}
                    onClick={onClick}
                    disabled={disabled ?? false}
                >
                    {children} {text}
                </Button>
            </div>
        </>
    );
};

export default BasicButton;

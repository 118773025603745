import LandingPage from "./scenesNuove/landingPage/LandingPage";
import FormSegnalazioni from "./scenesNuove/formSegnalazioni/FormSegnalazioni";
import {Route, Routes} from "react-router";
import TabellaSegnalazioniAdmin from "./scenesNuove/TabellaSegnalazioniAdmin/TabellaSegnalazioniAdmin";
import InfoSegnalazione from "./scenesNuove/InfoSegnalazione/InfoSegnalazione";
import SignInPage from "./scenesNuove/AuthPages/SignInPage/SignInPage";
import ThankYouPage from "./scenesNuove/formSegnalazioni/ThankyouPage/ThankYouPage";
import AddAziendaNuova from "./scenesNuove/AddAziendaNuova/AddAziendaNuova";
import PrivateRoute from "./componets/private-route/Private-route";
import PageNotFound from "./scenesNuove/PageNotFound/PageNotFound";
import AddUtenteNew from "./scenesNuove/AddUtenteNew/AddUtenteNew";
import {Navigate} from "react-router-dom";
import SignUpPage from "./scenesNuove/AuthPages/SignUpPage/SignUpPage";
import isAuthGuard from "./guards/isAuthGuard";
import isGuestGuard from "./guards/isGuestGuard";
import PrivateRouteSwitch from "./componets/PrivateRouteSwitch";
import notHaveSubscription from "./guards/notHaveSubscriptionGuard";
import isProfileCompleteGuard from "./guards/isProfileCompleteGuard";
import {ThemeProvider} from "@mui/material";
import theme from "./theme";
import AbbonatoConSuccessoPage from "./scenesNuove/CompletaProfiloPages/AbbonatoConSuccessoPage";
import ProfiloTab from "./scenesNuove/ImpostazioniPages/tabs/ProfiloTab/ProfiloTab";
import AziendaTab from "./scenesNuove/ImpostazioniPages/tabs/AziendaTab";
import PianoFatturazioneTab from "./scenesNuove/ImpostazioniPages/tabs/PianoFatturazioneTab/PianoFatturazioneTab";
import UtentiTab from "./scenesNuove/ImpostazioniPages/tabs/UtentiTab/UtentiTab";
import ImpostazioniPage from "./scenesNuove/ImpostazioniPages/ImpostazioniPage";
import CompletaProfiloPage from "./scenesNuove/CompletaProfiloPages/CompletaProfiloPage";
import ListaAdminAzienda from "./scenesNuove/ListaAdminAzienda/ListaAdminAzienda";
import isAdminGuard from "./guards/isAdminGuard";
import isAutomaticGuard from "./guards/isAutomaticGuard";
import isManualGuard from "./guards/isManualGuard";
import ResetPassword from "./scenesNuove/ResetPassword.ts/ResetPassword";
import ConfirmEmail from "./scenesNuove/ConfirmEmail/ConfirmEmail";
import Homepage from "./scenesNuove/Homepage/Homepage";
import DettaglioAzienda from "./scenesNuove/DettaglioAzienda/DettaglioAzienda";
import DettaglioOrganizzazione from "./scenesNuove/DettaglioOrganizzazione/DettaglioOrganizzazione";
import { useSelector } from "react-redux";

function App() {
	const haveSubscription = useSelector((state: any) => state.user.haveSubscription);
	const role = useSelector((state: any) => state.user.role);
	const isManual = useSelector((state: any) => state.user.isManual);

	return (
			<ThemeProvider theme={theme}>
			<Routes>
				<Route path="/" element={<Navigate to="/auth/login" />} />
				<Route path={`:tenant`} element={<LandingPage />} />

				<Route path="/auth">
					<Route path="" element={<Navigate to="login" />} />
					<Route element={<PrivateRoute evaluationFn={isGuestGuard} fallbackRoute="/route-switch" />}>
						<Route path="login" element={<SignInPage />} />
						<Route path="sign-up" element={<SignUpPage />} />
						<Route path="reset-password/:token" element={<ResetPassword />} />
						<Route path="confirm-registration" element={<ConfirmEmail />} />
					</Route>
				</Route>
				<Route path="/route-switch" element={<PrivateRouteSwitch />} />
				<Route path="/segnalazione" element={<FormSegnalazioni />} />
				<Route path="/segnalazione/riepilogo" element={<ThankYouPage />} />
				<Route path="/infoSegnalazione/:code" element={<InfoSegnalazione />} />
				<Route element={<PrivateRoute evaluationFn={isAuthGuard} fallbackRoute="/auth/login" />}>
					<Route path="/completa-profilo">
						<Route element={<PrivateRoute evaluationFn={() => notHaveSubscription(haveSubscription)} fallbackRoute="/route-switch" />}>
							<Route path="acquista" element={<CompletaProfiloPage />}></Route>
							<Route path="grazie" element={<AbbonatoConSuccessoPage />} />
						</Route>
					</Route>
					<Route element={<PrivateRoute evaluationFn={() => isProfileCompleteGuard(haveSubscription)} fallbackRoute="/route-switch" />}>
						<Route path="/TabellaSegnalazioniAdmin" element={<TabellaSegnalazioniAdmin />} />
						<Route path="/infoSegnalazione/:code" element={<InfoSegnalazione />} />
						<Route path="/Homepage" element={<Homepage />} />
						<Route path="/CompanyDetail/:companyId" element={<DettaglioAzienda />} />
						<Route path="/OrganizationDetail/:owner" element={<DettaglioOrganizzazione />} />
						<Route path="/AddAziendaNuova/:idOwner" element={<AddAziendaNuova />} />
						<Route path="/AddUtenteNew/:aziendaId" element={<AddUtenteNew />} />
						<Route path="/ListaAdminAzienda/:_id" element={<ListaAdminAzienda />} />
						<Route path="/impostazioni" element={<ImpostazioniPage />}>
							<Route path="profilo" element={<ProfiloTab />} />
							<Route element={<PrivateRoute evaluationFn={() => isAdminGuard(role)} fallbackRoute="/" />}>
								<Route element={<PrivateRoute evaluationFn={() => isManualGuard(isManual)} fallbackRoute="/" />}>
									<Route path="azienda" element={<AziendaTab />} />
								</Route>
								<Route path="utenti" element={<UtentiTab />} />
								<Route element={<PrivateRoute evaluationFn={() => isAutomaticGuard(isManual)} fallbackRoute="/" />}>
									<Route path="piani-fatturazione" element={<PianoFatturazioneTab />} />
								</Route>
							</Route>
						</Route>
					</Route>
				</Route>
				<Route path="*" element={<PageNotFound />} />
			</Routes>
			</ThemeProvider>
	);
}

export default App;

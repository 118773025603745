import axios, {AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig} from "axios";
import {useNavigate} from "react-router-dom";
import {EAuthSessionData, IAuthRefreshToken, IAuthSignInResponse} from "../types/auth";
import {IApiResponse, TApiListSearchParam} from "../types/commons";
import {stringify} from "qs";
import Cookies from "js-cookie";
import {jwtDecode} from "jwt-decode";
import {AUTH_URL} from "../api/api";
import axiosRetry from "axios-retry";
import i18n from "../i18n";

export class ApiService {
	navigate = useNavigate();
	private _axiosInstance: AxiosInstance;
	private _baseUrl: string;

	constructor(baseUrl: string) {
		this._baseUrl = baseUrl;
		this._axiosInstance = axios.create({
			baseURL: this._baseUrl,
			withCredentials: true
		});
		this._setHeaders();
		this._handleErrors();
	}

	public get<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
		return this._axiosInstance.get<any, T>(url, config);
	}

	public async post<B, R>(url: string, data: B, config?: AxiosRequestConfig): Promise<R> {
		return this._axiosInstance.post<any, R>(url, data, config);
	}

	public put<B, R>(url: string, data: B, config?: AxiosRequestConfig): Promise<R> {
		return this._axiosInstance.put<any, R>(url, data, config);
	}

	public async patch<B, R>(url: string, data: B, config?: AxiosRequestConfig): Promise<R> {
		return this._axiosInstance.patch<any, R>(url, data, config);
	}

	public delete<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
		return this._axiosInstance.delete<any, T>(url, config);
	}

	public list<T>(url: string, listSearchCountUrl: string, params?: TApiListSearchParam<T>, config?: AxiosRequestConfig): Promise<T | T[]> {
		let suffixParamStr = "";
		if (params) {
			const copyParams = params;
			suffixParamStr = "?";
			Object.entries(copyParams).forEach(([key, value]) => {
				if (Array.isArray(value)) {
					suffixParamStr = suffixParamStr + value.map((keyValuePair) => new URLSearchParams({[key]: keyValuePair})).join("&") + "&";
					delete params[key];
				}
			});

			suffixParamStr = suffixParamStr + stringify(params);
		}
		return this.get<T>(`${url}${listSearchCountUrl}${suffixParamStr}`, config);
	}

	public listCount<T>(url: string, params?: TApiListSearchParam<T>, config?: AxiosRequestConfig): Promise<T> {
		return this.list(url, "/list/count", params, config) as Promise<T>;
	}

	public listSearch<T>(url: string, params?: TApiListSearchParam<T>, config?: AxiosRequestConfig): Promise<T[]> {
		return this.list(url, "/list/search", params, config) as Promise<T[]>;
	}

	/**
	 * Metodo per la gestione del refresh dei token
	 * @private
	 */
	public async _refreshToken<IAuthSignInResponse>(): Promise<IAuthSignInResponse> {
		this._axiosInstance = axios.create({
			baseURL: AUTH_URL,
			withCredentials: true
		});
		const res = await this.post<IAuthRefreshToken, IApiResponse<IAuthSignInResponse>>("/auth/refresh", { refreshToken: "" });
		return res.data;
	}

	/**
	 * Metodo privato che configura gli header di default per le chiamate API
	 * @private
	 */
	private _setHeaders(): void {
		this._axiosInstance.interceptors.request.use((config) => {
			config.headers["x-access-token"] = sessionStorage.getItem(EAuthSessionData.ACCESS_TOKEN) ?? "";
			config.headers["Accept-Language"] = i18n.language;
			return config;
		});
	}

	/**
	 * Metodo privato per gestire il retry delle chiamate secondo determinate specifiche
	 * attualmente questo metodo viene utilizzato per gestire il retry dopo le risposte negative delle chiamte per token scaduto
	 * rientra nel processo di richiesta di refresh del token
	 * @private
	 */
	private _setRetry() {
		axiosRetry(this._axiosInstance, {
			retries: 2,
			retryCondition(error) {
				if (error.response) {
					if (error.response.status > 299) {
						return false;
					} else {
						const data = error.response.data as any as IApiResponse<any>;
						switch (data.code) {
							case "ERR_999":
								return data.message === "Token scaduto";
							default:
								return false;
						}
					}
				}
				return false;
			},
		});
	}

	/**
	 * Metodo privato che contiene un interceptor per la gestione degli errori delle chiamate API.
	 * Viene utilizzato anche per il flusso di refresh dei token.
	 * @private
	 */
	private _handleErrors(): void {
		this._axiosInstance.interceptors.response.use(
			async (response: AxiosResponse<IApiResponse<any>>) => {
				const originalRequest = response.config;
				if (response.status === 401) {
					return Promise.reject("ERRORS.USER_NOT_AUTHORIZED");
				} else if (response.status > 299) {
					return Promise.reject("ERRORS.GENERIC");
				}
				const data = response.data as any as IApiResponse<any>;
				if (data.code !== "") {
					switch (data.code) {
						case "AUTHERR":
							sessionStorage.removeItem(EAuthSessionData.ACCESS_TOKEN);
							Cookies.remove(EAuthSessionData.REFRESH_TOKEN_COOKIE);
							sessionStorage.removeItem(EAuthSessionData.AUTH_DATA);
							this.navigate("/Login");
							return Promise.reject(data.message);
						case 0:
						case "0":
							break;
						case "ERR_999":
							if (data.message === "Token scaduto") {
								return await this._tokenExpired(originalRequest).then((data) => data?.data.data);
							} else {
								return Promise.reject(data.message);
							}
						default:
							return Promise.reject(data.message);
					}
				}
				if (data.data) {
					return data.data;
				}
				return response;
			},
			(rejected: AxiosError<IApiResponse<any>>) => {
				if (rejected.response?.status && rejected.response?.status === 401) {
					return Promise.reject("ERRORS.USER_NOT_AUTHORIZED");
				} else if (!rejected.status || rejected.status > 500 || !rejected?.response?.data) {
					return Promise.reject("ERRORS.GENERIC");
				}
				const {data} = rejected.response;
				if (data.code !== "") {
					switch (data.code) {
						case "AUTHERR":
							this._rejectedUnAuthorized();
							return Promise.reject(data.message);
						default:
							return Promise.reject(data.message);
					}
				}
				return Promise.reject("ERRORS.GENERIC");
			},
		);
	}

	private _rejectedUnAuthorized(): void {
		sessionStorage.removeItem(EAuthSessionData.ACCESS_TOKEN);
		Cookies.remove(EAuthSessionData.REFRESH_TOKEN_COOKIE);
		sessionStorage.removeItem(EAuthSessionData.AUTH_DATA);
		this.navigate("/Login");
	}

	private async _tokenExpired(originalRequest: InternalAxiosRequestConfig<any>) {
		const rStatus = sessionStorage.getItem("refreshStatus");
		if (!rStatus) {
			sessionStorage.setItem("refreshStatus", "in refresh");
			const res: IApiResponse<IAuthSignInResponse> = await this._refreshToken();
			const { aToken } = res.data;
			sessionStorage.setItem(EAuthSessionData.ACCESS_TOKEN, aToken);
			originalRequest.headers["x-access-token"] = aToken;
			sessionStorage.removeItem("refreshStatus");
			return this._axiosInstance(originalRequest);
		}
	}
}

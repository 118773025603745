export const radioStyleSX = {
	"&&.Mui-checked": {
		color: "var(--primary-800) !important",
	},
	"&&.MuiRadio-root": {
		color: "var(--grey-400)",
	},
};

export const radioTextStyleSX = {
	color: "var(--black)",
};

import React from "react";
import {Box, Typography} from "@mui/material";
import "../../colors.css";
import { t } from "i18next";
import NotFoundComponent from "../../componets/NotFound/NotFoundComponent";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/logo-primary.svg";
import styles from "../Topbar/Topbar.module.css";

export default function PageNotFound() {
	const navigate = useNavigate();
	return (
	<>
		<Box sx={{paddingLeft: "1.5%", paddingTop: "1.5%" }}>
						<div className={styles.logo}>
							<img
								src={logo}
								alt="Logo"
							/>
						</div>
					</Box>
		<Box
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				flexDirection: "column",
			}}
		>	
			<NotFoundComponent onClickButton={() => navigate(-1)} titleButton={t("GO_BACK")} description={t("PAGE_NOT_FOUND_TITLE")} title={t("PAGE_NOT_FOUND_DESCRIPTION")} noButton={true} />
		</Box>
	</>
	);
}

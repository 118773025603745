import {ApiService} from "./ApiService";
import {REGISTER_URL} from "../api/api";
import {EAuthSessionData, IAuthSignUp} from "../types/auth";
import {
	ERegistryType,
	IImageTenant,
	IOperator,
	IRegistry,
	IRegistryAzienda,
	IRegistryCompanyInitialValues,
	IRegistryCompanyMeRequest,
	IRegistryOwner,
	IRegistryProfileInitialValues,
	IRegistryType,
	EContactType,
} from "../types/registry";
import {IApiResponse, ICommonType, IListCount, TApiListSearchParam} from "../types/commons";
import {t} from "i18next";

export class RegistryApiService extends ApiService {
	constructor() {
		super(REGISTER_URL);
	}

	signUp(body: IAuthSignUp): Promise<Partial<IAuthSignUp>> {
		return new Promise<Partial<IAuthSignUp>>(async (resolve, reject) => {
			try {
				const response = await this.post<IAuthSignUp, Partial<IAuthSignUp>>("/registry/public/createCompanyUser", body);
				sessionStorage.setItem(EAuthSessionData.SUCCESS_SIGNUP, t("SIGN_UP.SUCCESS"));
				return resolve(response);
			} catch (e: any) {
				if (e.split(':')[0] === 'AuthModuleError') {
					return reject('SIGN_UP.SUBMIT_ERROR');
				}
				return reject(e);
			}
		});
	}

	infoMe(): Promise<IRegistry> {
		return this.get<IRegistry>("/registry/info/me");
	}

	async patchInfoMe(data: IRegistryProfileInitialValues): Promise<IRegistry> {
		return this.patch<IRegistryProfileInitialValues, IRegistry>("/registry/info/me", data);
	}

	async updateCompanyMe(data: IRegistryCompanyInitialValues): Promise<IRegistry> {
		const body: IRegistryCompanyMeRequest = {
			company: {
				business_name: data.business_name,
				website: data.website,
				address: {
					street: data.street,
					city: data.city,
					postal_code: data.postal_code,
					province: data.province,
					state: data.country,
				},
			},
		};
		return this.patch<IRegistryCompanyMeRequest, IRegistry>("/registry/info/me", body);
	}

	infoMeProfiloInitialValues(): Promise<IRegistryProfileInitialValues> {
		return new Promise<IRegistryProfileInitialValues>((resolve, reject) =>
			this.infoMe()
				.then((response) => {
					const emailContact = response.id_contact.find((contact) => (contact.id_contact_type ? contact.id_contact_type.code === EContactType.EMAIL : false));
					const phoneContact = response.id_contact.find((contact) => (contact.id_contact_type ? contact.id_contact_type.code === EContactType.PHONE : false));
					const tenant = response.tenant;
					sessionStorage.setItem(EAuthSessionData.TENANT, tenant);
					let businessName = response.business_name;
					if (response.id_registry_owner && typeof response.id_registry_owner !== "string") {
						businessName = response.id_registry_owner.business_name;
					}

					const initialValues: IRegistryProfileInitialValues = {
						firstname: response.firstname,
						surname: response.surname,
						email: emailContact ? emailContact.contact : "",
						phone: phoneContact ? phoneContact.contact : "",
						business_name: businessName,
					};
					return resolve(initialValues);
				})
				.catch((e) => {
					return reject(e);
				}),
		);
	}

	infoCompanyMeInitialValues(): Promise<IRegistryCompanyInitialValues> {
		return new Promise<IRegistryCompanyInitialValues>((resolve, reject) => {
			this.infoMe()
				.then((response) => {
					if (typeof response.id_registry_owner === "string") return reject("id_registry_owner is not an object");
					const registryOwner = response.id_registry_owner as IRegistryOwner;
					const tenant = response.tenant;
					sessionStorage.setItem(EAuthSessionData.TENANT, tenant);

					const initialValues: IRegistryCompanyInitialValues = {
						business_name: registryOwner.business_name,
						city: registryOwner.id_registry_address[0].city,
						country: registryOwner.id_registry_address[0].state,
						postal_code: registryOwner.id_registry_address[0].postal_code,
						province: registryOwner.id_registry_address[0].province,
						website: registryOwner.id_contact[0].contact,
						street: registryOwner.id_registry_address[0].street,
					};
					return resolve(initialValues);
				})
				.catch((e) => {
					return reject(e);
				});
		});
	}

	isManual(): Promise<boolean> {
		return new Promise<boolean>(async (resolve) => {
			const response = await this.infoMe();
			const tenant = response.tenant;
			sessionStorage.setItem(EAuthSessionData.TENANT, tenant);
			return resolve(response.isManual ?? false);
		});
	}

	createRegistry(body: Partial<IRegistry | any>): Promise<Partial<IRegistry>> {
		return new Promise<any>(async (resolve, reject) => {
			try {
				const response = await this.post<Partial<IRegistry>, Partial<IRegistry>>("/registry/full", body);
				return resolve(response);
			} catch (e) {
				return reject(e);
			}
		});
	}

	//BUG: L'header da problemi di CORS
	createOperator(body: Partial<IRegistry>): Promise<Partial<IRegistry>> {
		return new Promise<any>(async (resolve, reject) => {
			try {
				const response = await this.post<Partial<IRegistry>, Partial<IRegistry>>("/registry/createOperatorUser", body);
				return resolve(response);
			} catch (e) {
				return reject(e);
			}
		});
	}

	deleteRegistry(id: string): Promise<any> {
		return new Promise<any>(async (resolve, reject) => {
			try {
				const response = await this.delete<any>(`/registry/full/${id}`);
				return resolve(response);
			} catch (e) {
				return reject(e);
			}
		});
	}

	countRegistry(params?: TApiListSearchParam<any>): Promise<number> {
		return this.listCount<any>("registry", params);
	}

	getDipendenti(): Promise<IOperator[]> {
		return this.listSearch<IOperator>("registry", {registry_type: ERegistryType.DIPENDENTE});
	}

	// TODO: Add method for search employee of specific company

	getDipendentiForCompany(tenant: string): Promise<IOperator[]> {
		return this.listSearch<IOperator>("registry", {registry_type: ERegistryType.DIPENDENTE, tenant: tenant});
	}

	searchRegistry(params?: TApiListSearchParam<IRegistry>): Promise<IRegistry[]> {
		return this.listSearch<IRegistry>("registry", params);
	}

	countContactType(params?: TApiListSearchParam<IListCount>): Promise<IListCount> {
		return this.listCount<IListCount>("contact-type", params);
	}

	getContactType(params?: TApiListSearchParam<ICommonType>): Promise<ICommonType[]> {
		return this.listSearch<ICommonType>("contact-type", params);
	}

	countRegistryAddressType(params?: TApiListSearchParam<IListCount>): Promise<IListCount> {
		return this.listCount<IListCount>("registry-address-type", params);
	}

	getRegistryAddressType(params?: TApiListSearchParam<ICommonType>): Promise<ICommonType[]> {
		return this.listSearch<ICommonType>("registry-address-type", params);
	}

	countRegistryTypes(params?: TApiListSearchParam<IListCount>): Promise<IListCount> {
		return this.listCount<IListCount>("registry-type", params);
	}

	getRegistryTypes(params?: TApiListSearchParam<IRegistryType>): Promise<IRegistryType[]> {
		return this.listSearch<IRegistryType>("registry-type", params);
	}

	async getRegistryIdByType(code: string): Promise<string | undefined> {
		const res: IRegistry[] = await this.listSearch<IRegistry>("registry-type", {code});
		if (res.length > 0) {
			return res[0]._id;
		} else {
			return undefined;
		}
	}

	async getContactIdByType(code: string): Promise<string | undefined> {
		const res: ICommonType[] = await this.listSearch<ICommonType>("contact-type", {code});
		if (res.length > 0) {
			return res[0]._id;
		} else {
			return undefined;
		}
	}

	fetchAllRegistryByType(type: ERegistryType): Promise<IRegistry[]> {
		return this.listSearch<IRegistry>("registry", {registry_type: type});
	}

	getCompanyLogo(tenant: string) {
		return this.get<IImageTenant>("/registry/search/imageByTenant", {headers: {"x-tenant": tenant}});
	}

	getRegistryDetail(id: string, params?: TApiListSearchParam<any>) {
		return this.get<IRegistry[]>("/registry/detail/" + id, {params});
	}

	createCompany(body: any) {
		return this.post<any, IRegistry>("/registry/full", body);
	}
}
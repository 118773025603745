import React from "react";
import {Grid, Stack} from "@mui/material";
import PlanCard from "./components/PlanCard";
import HistoryCard from "./components/HistoryCard";
import FatturazioneCard from "./components/FatturazioneCard";

const PianoFatturazioneTab = () => {
    return (
            <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                    <Stack spacing={2}>
                        <PlanCard/>
                        <FatturazioneCard/>
                    </Stack>
                </Grid>
                <Grid item xs={12} md={4}>
                    <HistoryCard/>
                </Grid>
            </Grid>
    )
}

export default PianoFatturazioneTab;

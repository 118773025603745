import React, {useState} from "react";
import {Button, Form, InputGroup} from "react-bootstrap";
import styles from "./ButtonTextField.module.css";

interface Props {
    text: string;
    onTextChange: (text: string) => void; // Funzione di callback per passare il valore al componente padre
    onButtonClick: () => void;
}

function ButtonTextField({text, onTextChange, onButtonClick}: Props) {
    const [inputText, setInputText] = useState("");

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputText(event.target.value);
        onTextChange(event.target.value);
    };

    const handleButtonClick = () => {
        onButtonClick(); // Gestisci il click sul pulsante
    };

    return (
        <div className={styles.container}>
            <InputGroup className={styles.inputGroup} size="lg">
                <Form.Control
                    placeholder="key code"
                    style={{borderRadius: "50px 0 0 50px", borderColor: "white"}}
                    value={inputText}
                    onChange={handleInputChange}
                />
                <Button
                    variant="outline"
                    id="button-addon2"
                    className={styles.button}
                    style={{
                        borderRadius: "0 50px 50px 0",
                        borderColor: "var(--primary-500)",
                        backgroundColor: "var(--primary-500)",
                        color: "black",
                        fontWeight: "bold",
                    }}
                    onClick={handleButtonClick}
                >
                    <span style={{
                        textTransform: "none",
                        fontSize: "16px"
                    }}>{text}</span>
                </Button>
            </InputGroup>
        </div>
    );
}

export default ButtonTextField;
